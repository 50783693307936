import React, { useState } from "react";
import { useTranslate } from "react-admin";

import { getChainIndexDetails } from "../../../api/merchant";
import { useChains } from "../../../components/FetchConfig";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import { useAlerting } from "../../../hooks";
import { enumWalletType } from "../../../utils/constant";
import {
  sortItemsAlphabetically,
  todaysDateForFileName,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";

const translatePrefix = "AddressGeneration";

interface ILocalProps {
  chain_name: string;
  wallet_type: string;
  numOfAddress: string;
}
const initFields = {
  chain_name: "",
  wallet_type: "",
  numOfAddress: "",
};

interface IChainIndex {
  merchant_id: string;
  chain_type: string | number;
  chain_id: string;
  wallet_type: string | number;
  latest_wallet_version: string | number;
  wallet_index_start: null;
  wallet_index_end: null;
  wallet_last_generated_index: string | number;
}

const walletTypeArr = ["1", "3"]; // Client Wallet and Invoker Wallet

const walletTypeFileNameMapping: Record<string, string> = {
  "1": "clientwallet",
  "3": "invokerwallet",
};

const AddressGenerationDialog = () => {
  const [fields, setFields] = useState<ILocalProps>(initFields);
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);

  const { chainObj, selectChainByPieces } = useChains();
  const solObjArr = Object.entries({ ...chainObj }).filter(
    ([key, value]: any) => {
      return (
        (value.chain_type == 5 && value.chain_id == "2") ||
        (value.chain_type == 5 && value.chain_id == "1")
      );
    }
  );

  const handleConfirm = async () => {
    //Check TextFields Has Inputs
    if (!fields.chain_name) {
      return alerting("info", t("phChain_name"));
    }
    if (!fields.wallet_type) {
      return alerting("info", t("phWallet_type"));
    }
    if (!fields.numOfAddress) {
      return alerting("info", t("phNumOfAddress"));
    }
    const { chain_name } = fields;
    const { chain_type, chain_id, chain_code } = selectChainByPieces({
      chain_name,
    });

    const params = {
      chain_type: chain_type,
      chain_id: chain_id,
      wallet_type: fields.wallet_type,
    };
    //get index_range & wallet_version from api
    const result: IChainIndex = await getChainIndexDetails(params);
    let proposeFromIndex = Number(result.wallet_last_generated_index) + 1; //from_index
    let proposeToIndex =
      Number(result.wallet_last_generated_index) + Number(fields.numOfAddress); //to_index
    //Check the From_index and To_index
    if (result.wallet_index_start) {
      if (proposeFromIndex < Number(result.wallet_index_start)) {
        return alerting("info", t("req_addr_num_is_smaller_than_starRange"));
      }
    }

    if (result.wallet_index_end) {
      if (proposeFromIndex > Number(result.wallet_index_end)) {
        return alerting("info", t("req_addr_num_is_larger_than_endRange"));
      }
      if (
        proposeFromIndex > Number(result.wallet_index_start) &&
        proposeToIndex > Number(result.wallet_index_end)
      ) {
        proposeToIndex = Number(result.wallet_index_end);
      }
    }

    //Text Content
    const txtContent = {
      chain_type: chain_type,
      chain_id: chain_id,
      wallet_type: fields.wallet_type,
      wallet_version: result.latest_wallet_version,
      from_index: proposeFromIndex,
      to_index: proposeToIndex,
      max_batch_size: 10000,
    };

    const fileData = JSON.stringify(txtContent); //make data to be stirng first
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const timeStamp = todaysDateForFileName();

    const walletTypeFileName = walletTypeFileNameMapping[fields.wallet_type];

    link.download = `${chain_code}_${timeStamp}_${walletTypeFileName}_addr_req`;
    link.href = url;
    link.click();
    alerting("success", t("success_generate_txt"));
    zusDialog.close();
    setFields(initFields);
  };

  const handleOnSelectEvent = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };

  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "wallet_type") {
      setFields((fields) => ({
        ...fields,
        wallet_type: "",
      }));
    }
    if (filterField === "numOfAddress") {
      setFields((fields) => ({
        ...fields,
        numOfAddress: "",
      }));
    }
  };
  const handleCloseResetStates = () => {
    zusDialog.close();
    setFields(initFields);
  };
  const handleSelectAddressNum = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFields((fields) => ({
      ...fields,
      [name]: value,
    }));
  };

  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    return setFields((fields: any) => ({
      ...fields,
      [type]: value,
    }));
  };

  return (
    <>
      <Dialog
        open={zusDialog.match("gen_address_dialog")}
        onClose={handleCloseResetStates}
        fullWidth={true}
      >
        <DialogTitle>{t("address_generation")}</DialogTitle>

        <DialogContent>
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("phChain_name")}</InputLabel>
            <Select
              name="chain_name"
              onChange={handleOnSelectEvent}
              defaultValue=""
            >
              {solObjArr.map(([key, { name }], i) => (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("phWallet_type")}</InputLabel>
            <Select
              name="wallet_type"
              value={fields.wallet_type}
              onChange={onSelectChange("wallet_type")}
            >
              {sortItemsAlphabetically(Object.entries(enumWalletType))
                .filter(([key, value]: any) => walletTypeArr.includes(key))
                .map(([key, value]: any, i: string) => (
                  <MenuItem
                    key={key}
                    value={Number(key)}
                    onClick={(e) => handleClick(e, value, "wallet_type")}
                  >
                    {translate(`enumConstants.${enumWalletType[String(key)]}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("phNumOfAddress")}</InputLabel>
            <Select
              name="numOfAddress"
              value={fields.numOfAddress}
              onChange={handleSelectAddressNum}
            >
              <MenuItem value={10000}>10,000</MenuItem>
              <MenuItem value={50000}>50,000</MenuItem>
              <MenuItem value={100000}>100,000</MenuItem>
              <MenuItem value={200000}>200,000</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button color="secondary" variant="contained" onClick={handleConfirm}>
            {t("confirm")}
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={handleCloseResetStates}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressGenerationDialog;
